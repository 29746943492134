<template>
    <p>
        <span v-if="user !== ''"><b>{{user}}</b>:</span> {{message.message}} <span class="timestamp">{{message.createdAt}}</span>
    </p>
</template>

<script>
    export default {
        name: "SMSChatMessage",
        props: {
            message: {
                createdAt: String,
                message: String,
                messageType: String,
            }
        },
        computed: {
            user: function () {
                if (this.message.messageType === "MsgToUser") {
                    return "Elevator";
                }else if (this.message.messageType === "MsgToCamera") {
                    return "You";
                }else if (this.message.messageType === "System") {
                    return "";
                }
                return "";
            }
        }
    }
</script>

<style scoped>
    .timestamp {
        float: right;
        opacity: 0.7;
        text-align: right;
    }
</style>
