<template>
  <sui-container id="main dashboard">
    <sui-menu id="main-menu">
      <sui-menu-item header link>
        <img src="../../src/assets/tsaro-logo-header.png" id="logo"/>
      </sui-menu-item>
      <sui-menu-menu position="right">
      </sui-menu-menu>
    </sui-menu>


    <sui-grid columns="one" centered id="expired" v-if="expiredLink">
      <sui-grid-row>
        <sui-grid-column :width="16" :mobile="16" :tablet="16" :computer="16">
          <!-- ============ Main Content BEGIN ============ -->
          <h3 is="sui-header">Your link has expired, please login to see the call history.</h3>
          <sui-button @click.prevent="goHome" color="blue" icon="sign-in">Login</sui-button>
          <!-- ============ Main Content END ============ -->
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
    <sui-grid columns="one" centered id="normal" v-else>
      <sui-grid-row>
        <sui-grid-column :width="16" :mobile="16" :tablet="16" :computer="16">
          <!-- ============ Main Content BEGIN ============ -->
          <sui-form>
            <sui-form-fields>
              <sui-form-field>
                <sui-button @click.prevent="hangUp" color="red" icon="ban">Hang Up</sui-button>
              </sui-form-field>
              <sui-form-field>
                <sui-button floated="right" @click.prevent="connect" color="green" icon="phone"
                            :disabled="connectButtonDisabled">Connect
                </sui-button>
              </sui-form-field>
              <!--
              <sui-form-field>
                <sui-button @click.prevent="test" color="blue" icon="plus">Test</sui-button>
              </sui-form-field>
              -->
            </sui-form-fields>
          </sui-form>
          <p></p>
          <p>
            Status: {{ status }}
            <sui-icon name="check circle outline" v-if="status==='registered'" color="green"></sui-icon>
            <sui-icon name="spinner" v-else-if="status==='connecting'" color="red" loading></sui-icon>
            <sui-icon name="sync" v-else color="blue" loading></sui-icon>
          </p>
          <p></p>
          <sui-divider></sui-divider>
          <SMSSession :session="session" :status="status"></SMSSession>

          <!-- ============ Main Content END ============ -->
        </sui-grid-column>
      </sui-grid-row>

      <sui-grid-row>
        <sui-grid-column>
          <sui-segment>
            <h4 is="sui-header" dividing>Message</h4>
            <sui-form v-if="this.toSipEndpoint">
              <sui-form-fields inline>
                <sui-form-field>
                  <sui-input placeholder="Message" id="input-message" v-model="messageInput"
                             @submit="submitButtonClick"></sui-input>
                </sui-form-field>
                <sui-form-field>
                  <sui-button-group>
                    <!--

                    <sui-dropdown button text="Quick Response">
                      <sui-dropdown-menu>
                        <sui-dropdown-item v-for="q in quickResponseMessages" :value="q.value"
                                           :key="q.value" @click.prevent="sendResponseMessage(q.message)">
                          {{ q.message }}
                        </sui-dropdown-item>
                      </sui-dropdown-menu>
                    </sui-dropdown>
                    -->

                    <sui-button id="message-send" color="blue" @click.prevent="submitButtonClick">Submit
                    </sui-button>
                  </sui-button-group>
                </sui-form-field>
              </sui-form-fields>
            </sui-form>
            <s-m-s-chat-message v-for="m in messages" :key="m.createdAt + m.message" :message="m"></s-m-s-chat-message>
          </sui-segment>
        </sui-grid-column>

      </sui-grid-row>
    </sui-grid>

  </sui-container>
</template>

<script>
import JsSIP from "jssip";
import SMSSession from "@/views/dashboard/video/SMSSession";
import SMSChatMessage from "@/views/dashboard/camera/SMSChatMessage";
import moment from "moment";

export default {
  name: "TestIncoming",
  components: {SMSSession, SMSChatMessage},
  data: function () {
    const quickResponseMessages = [
      "Are you okay?",
      "Can you hear me?",
      "Are you injured?",
    ];
    return {
      quickResponseMessages: quickResponseMessages.map((i) => {
        return {
          value: i,
          message: i,
        }
      }),
      messageInput: "",
      toSipEndpoint: null,

      // SIP related
      sipEndpoint: "xxx@nahida.yuri.moe",
      expiredLink: false,
      connectButtonDisabled: false,

      // 'connecting' / disconnected' / 'connected' / 'registered'
      status: 'disconnected',
      uri: '',

      settings: {
        display_name: "caller",
        uri: "xxx@nahida.yuri.moe",
        password: "xxx",
        socket: {
          uri: "wss://nahida.yuri.moe:5063",
          via_transport: "wss"
        },
        registrar_server: "nahida.yuri.moe",
        contact_uri: null,
        authorization_user: null,
        instance_id: null,
        session_timers: true,
        use_preloaded_route: false,
        pcConfig: {
          rtcpMuxPolicy: "negotiate",
          iceServers: [
            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun2.l.google.com:19302"
              ]
            }
          ]
        },
        callstats: {
          enabled: false,
          AppID: null,
          AppSecret: null
        }
      },

      session: null,
      incomingSession: null,

      dialTo: null,


      mounted: false,
      ua: null,
      u: null,
      messages: []
    }
  },
  created: async function () {
    console.log("created");
    this.sipEndpoint = this.$route.params.sessionKey + "@nahida.yuri.moe";
    this.settings.uri = this.sipEndpoint;
    this.settings.password = this.$route.params.sessionKey;
    this.toSipEndpoint = null;
  },
  methods: {
    submitButtonClick: function () {
      this.sendMessage(this.messageInput);
      this.messageInput = "";
    },
    sendResponseMessage: function (message) {
      this.sendMessage(message);
    },
    sendMessage: function (message) {
      //console.log("sending message" + message);
      if (this.toSipEndpoint !== null) {
        this.ua.sendMessage(this.toSipEndpoint, message, {
          success: this.sipMsgSuccess,
          error: this.sipMsgError
        });
        this.toSipEndpoint = null;
      }
    },
    addLogMessage: function(msgType, msg) {
      this.messages.unshift({
        message: msg,
        createdAt: moment().format("h:mm:ss a"),
        messageType: msgType
      });
    },
    sipMsgSuccess: function(e) {
      console.log("sipMsgSuccess" + e);
    },
    sipMsgError: function(e) {
      console.log("sipMsgError" + e);
    },

    test: function () {
      console.log("test");
      if (this.toSipEndpoint !== null) {
        this.ua.sendMessage(this.toSipEndpoint, "test", {
          success: this.sipMsgSuccess,
          error: this.sipMsgError
        });
      }

    },
    goHome: async function () {
      await this.$router.push({name: 'Home'});
    },
    connect: async function () {
      this.mounted = true;
      const logger = console;
      const settings = this.settings;
      const socket = new JsSIP.WebSocketInterface(settings.socket.uri);

      if (settings.socket['via_transport'] !== 'auto')
        socket['via_transport'] = settings.socket['via_transport'];

      try {
        this.ua = new JsSIP.UA(
            {
              uri: settings.uri,
              password: settings.password,
              'display_name': settings.display_name,
              sockets: [socket],
              'registrar_server': settings.registrar_server,
              'contact_uri': settings.contact_uri,
              'authorization_user': settings.authorization_user,
              'instance_id': settings.instance_id,
              'session_timers': settings.session_timers,
              'use_preloaded_route': settings.use_preloaded_route
            });

        window.UA = this.ua;
        console.log("successfully created instance")
      } catch (error) {
        console.log({
          level: 'error',
          title: 'wrong SIP settings',
          message: error.message
        });

      }


      this.ua.on('connecting', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connecting" event');

        this.uri = this.ua.configuration.uri.toString();
        this.status = 'connecting';


      });


      this.ua.on('connected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connected" event');

        this.status = 'connected';
      });

      this.ua.on('disconnected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "disconnected" event');

        this.status = 'disconnected';
        this.hangUp();
        this.expiredLink = true;

        this.addLogMessage("System", "disconnected");
      });

      this.ua.on('registered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "registered" event');

        this.status = 'registered';
        this.addLogMessage("System", "registered");
      });

      this.ua.on('unregistered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "unregistered" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }
      });

      this.ua.on('registrationFailed', (data) => {
        if (!this.mounted)
          return;

        logger.log('UA "registrationFailed" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }

        logger.log({
          level: 'error',
          title: 'Registration failed',
          message: data.cause
        });
      });

      this.ua.on('newMessage', (msg) => {
        console.log("new message");
        console.dir(msg);
        if (msg.originator === 'local') {
          this.addLogMessage("MsgToCamera", msg.request.body);
        }else {
          this.toSipEndpoint = (msg.request.from + " ").match(/<(\S+)>/)[1];
          this.addLogMessage("MsgToUser", msg.request.body);
        }
      });


      // New RTC Connection
      this.ua.on('newRTCSession', (data) => {

        logger.log('UA "newRTCSession" event');

        if (!this.mounted) {
          return;
        }

        window.SESSION = data.session;
        if (data.originator === 'local') {
          return;
        }

        logger.log('UA "newRTCSession" event');

        const session = data.session;

        // Avoid if busy or other incoming
        if (this.session || this.incomingSession) {
          logger.log('incoming call replied with 486 "Busy Here"');

          session.terminate(
              {
                'status_code': 486,
                'reason_phrase': 'Busy Here'
              });

          return;
        }

        this.incomingSession = session;

        session.on('failed', () => {
          console.log("session failed");

          this.incomingSession = null;
          this.session = null;
        });

        session.on('ended', () => {
          console.log("session ended");

          this.incomingSession = null;
          this.session = null;
        });

        session.on('accepted', () => {
          console.log("session accepted");

          this.session = session;
          this.incomingSession = null;
        });

        this.answerPhone();

      });

      //Star UA
      this.addLogMessage("System", "starting");
      this.ua.start();

    },
    answerPhone: async function () {
      console.log("answerPhone");
      const session = this.incomingSession;
      session.answer({
        pcConfig: this.settings.pcConfig
      });
      this.addLogMessage("System", "call answered");
    },

    hangUp: async function () {
      if (this.session) {
        this.session.terminate();
      }
      if (this.incomingSession) {
        this.incomingSession.terminate();
      }
      if (this.ua) {
        this.ua.stop();
      }
    }
  }
}
</script>

<style scoped>
#logo {
  width: 3.5em;
}

#main-menu {
  margin-top: 20px;
}

#sidebar .menu {
  width: 100%;
}

.ui.menu .hide {
  display: none;
}

.ui.inverted.blue.menu, .ui.inverted.menu .blue.active.item {
  background-color: #3a98fe;
}

#user-role-text {
  color: lightgray;
  margin-left: 5px;
  font-size: 80%;
}
</style>
